import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// components
import BlogFooter from "../components/blog/BlogFooter"

// importing images
import hostparty from "../images/blog/host.png"
import openspotify from "../images/blog/openSpotify.png"
import thequeue from "../images/blog/thequeue.png"

const MusicBlog = () => {
  return (
    <PageWrapper>
      <Wrapper>
        <BlogHeader>
          <Yellow>Guide:</Yellow> Spil musik til din næste fest med Queue
        </BlogHeader>
        <Teaser>
          Lad gæster tilføje musik og upvote sange i musikkøen med Queue. Så
          spiller højtaleren altid den fedeste musik!
        </Teaser>

        <Section>
          <HeaderContainer>
            <Header>Step 1: Lav festen</Header>
            <YellowBar />
          </HeaderContainer>
          <Paragraph>
            Åben Queue, tryk på “Host Party” knappen nederst på skærmen og så
            har du lavet en fest!
          </Paragraph>
          <ImageContainer>
            <Image1 />
          </ImageContainer>
        </Section>

        <Section>
          <HeaderContainer>
            <Header>Step 2: Tilføj sange</Header>
            <YellowBar />
          </HeaderContainer>
          <Paragraph>
            Så skal der tilføjes sange. Tryk på den store gule ‘Start’ knap i
            midten af skærmen. Åben så Spotify/Apple Music og sæt en sang på.
            Når det er gjort, åbner du Queue igen (nu ser det lidt anderledes
            ud) og trykker på “Queue Songs” aller nederst på skærmen. Find de
            fedeste sange og tryk “Add”!
          </Paragraph>
          <ImageContainer>
            <Image2 />
          </ImageContainer>
        </Section>

        <Section>
          <HeaderContainer>
            <Header>Step 3: Upvote sange</Header>
            <YellowBar />
          </HeaderContainer>
          <Paragraph>
            Når du upvoter en sang, kommer den længere op i musikkøen og bliver
            dermed spillet før. Du upvoter sange ved at gå ind under “See Queue”
            lige ovenover “Queue Songs” - her kan du også se alle sange i køen.
            I venstre side af hver sang er en emoji. Den trykker du på.
            <br />
            <br />
            Som vært, kan du også altid skippe og pause musik, mens dine gæster
            kun kan tilføje og upvote sange.
          </Paragraph>
          <ImageContainer>
            <Image3 />
          </ImageContainer>
          <Paragraph>
            <br />
            <br />
            Færdig med festen? Tryk på settings i toppen til højre og tryk “Stop
            Party”.
          </Paragraph>
        </Section>

        <More>
          <BoldParagraph>
            Læs videre og se, hvordan du inviterer til din næste fest med Queue
            <span role="img" aria-label="pointing-right">
              👉
            </span>
          </BoldParagraph>
          <StyledLink to="/invite-guide">
            <Button>Læs guide</Button>
          </StyledLink>
        </More>

        <BlogFooter />
      </Wrapper>
    </PageWrapper>
  )
}

export default MusicBlog

// Page Wrapper
const PageWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
`

// Content wrapper
const Wrapper = styled.div`
  max-width: 700px;
  height: auto;

  background-color: inherit;

  margin: 2.5rem 2.5rem 0 2.5rem;
`

// Blog header and subheader
const BlogHeader = styled.h1`
  font-family: "Avenir";
  font-size: 2rem;
  font-weight: 600;

  line-height: 1.3;

  margin-bottom: 0.7rem;

  @media (min-width: 500px) {
    font-size: 2.5rem;
  }

  @media (min-width: 650px) {
    font-size: 2.8rem;
  }
`

const Yellow = styled.span`
  color: #fdde68;
`

const Teaser = styled.p`
  font-family: "Avenir";
  font-size: 1.3rem;
  font-style: italic;
  line-height: 1.2;

  margin-bottom: 3rem;

  @media (min-width: 500px) {
    font-size: 1.4rem;
  }

  @media (min-width: 650px) {
    font-size: 1.6rem;
  }
  @media (min-width: 750px) {
    font-size: 1.8rem;
  }
`

// -------------

// Section styles
const Section = styled.section`
  max-width: inherit;
  min-height: 400px;

  height: auto;

  margin-bottom: 2.5rem;

  display: flex;
  flex-direction: column;
`

// Sticking header and bar together
const HeaderContainer = styled.div`
  height: auto;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  margin-bottom: 1rem;
`

const Header = styled.h1`
  font-family: "Avenir";
  font-size: 1.7rem;
  font-weight: 700;

  line-height: 1;

  margin-bottom: 0.6rem;

  @media (min-width: 500px) {
    font-size: 2rem;
  }

  @media (min-width: 650px) {
    font-size: 2.2rem;
  }
`

const YellowBar = styled.div`
  width: 80px;
  height: 15px;

  background-color: #fdde68;

  @media (min-width: 500px) {
    width: 100px;
  }

  @media (min-width: 650px) {
    width: 115px;
  }
`

const Paragraph = styled.p`
  font-family: "Avenir";
  font-size: 1rem;
  font-style: normal;

  line-height: 1.3;

  margin-bottom: 1.5rem;

  @media (min-width: 500px) {
    font-size: 1rem;
  }

  @media (min-width: 650px) {
    font-size: 1.2rem;

    margin-bottom: 2rem;
  }

  @media (min-width: 750px) {
    font-size: 1.4rem;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
`

const Image = styled.div`
  width: 250px;
  height: 250px;
  background-repeat: no-repeat;
  background-size: contain;

  @media (min-width: 500px) {
    width: 280px;
    height: 280px;
  }

  @media (min-width: 650px) {
    width: 320px;
    height: 320px;
  }

  @media (min-width: 750px) {
    width: 370px;
    height: 370px;
  }
`

// -------------

// Images extended from Image
const Image1 = styled(Image)`
  background-image: url(${hostparty});
`

const Image2 = styled(Image)`
  background-image: url(${openspotify});
`

const Image3 = styled(Image)`
  background-image: url(${thequeue});
`

// Link to next guide
const More = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
`

const BoldParagraph = styled.p`
  font-family: "Avenir";
  font-size: 1.2rem;

  line-height: 1.3;

  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 500px) {
    font-size: 1.4rem;
  }

  @media (min-width: 650px) {
    font-size: 1.6rem;
  }
`

const Button = styled.div`
  width: 150px;
  height: 40px;

  background-color: #fdde68;

  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);

  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 500px) {
    width: 200px;
    height: 45px;
  }

  @media (min-width: 650px) {
    width: 250px;
    height: 50px;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  font-family: "Avenir";
  font-size: 1.2rem;
  text-decoration: none;

  color: black;

  @media (min-width: 650px) {
    font-size: 1.4rem;
  }
`
